import { gql } from '@apollo/client'

export const AUTHENTICATE_USER_MUTATION = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticateUser(input: { email: $email, password: $password }) {
      success
      token
      refreshToken
      expiresIn
      message
      user {
        id
        email
        firstName
        lastName
        role
        companyId
        lastLoggedInCompanyId
        isEndUserAgreementAccepted
        passwordExpiresAt
        permissions {
          id
          companyId
        }
      }
    }
  }
`


export const AUTHENTICATE_FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!, ) {
    forgotPassword(input: { email: $email}) {
      success
      message
    }
  }
`


export const AUTHENTICATE_CONFIRM_PASSWORD_MUTATION = gql`
  mutation confirmPassword($email: String!, $newPassword: String!, $token: String!, $tokenUuid: String!) {
    confirmPassword(input: { email: $email, newPassword: $newPassword, token: $token, tokenUuid: $tokenUuid }) {
      success
      message
    }
  }
`

export const AUTHENTICATE_CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($id: ID!, $oldPassword: String!, $newPassword: String!) {
    changePassword(input: { id: $id, oldPassword: $oldPassword, newPassword: $newPassword }) {
      success
      message
    }
  }
`

export const SET_ACTIVE_USER_SESSION_MUTATION = gql`
  mutation setActiveUserSession($companyId: ID!) {
    setActiveUserSession(input: { companyId: $companyId }) {
      success
      token
      refreshToken
      expiresIn
      user {
        id
        email
        firstName
        lastName
        role
        companyId
        lastLoggedInCompanyId
        isEndUserAgreementAccepted
        passwordExpiresAt
        permissions {
          id
          companyId
        }
      }
    }
  }
`

export const AUTH_LOGOUT_MUTATION = gql`
   mutation logout($id: ID!, ) {
     logout(input: { id: $id}) {
       success
       message
     }
   }
 `
