import React from 'react'
import { Row, Col, Button, Alert } from 'reactstrap'
import { Formik } from 'formik'
import DynamicFormikField from '../../../components/forms/DynamicFormikField'
import TinySpinner from '../../../components/TinySpinner'
import { FieldConfigs } from './AcceptInvitationFields'
import useAcceptInvitationForm from './useAcceptInvitationForm'
import logo from '../../../assets/images/logo-light.png'


const AcceptInvitation = React.memo(function AcceptInvitation(props) {
  const { error, formikOptions, success, successMessage, errorMessage } = useAcceptInvitationForm({
    ...props,
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formikOptions

  return (
    <React.Fragment>
      <div className="">
        <div className="container-content">
          <Row className="">
            <Col>
              <div id="cardCollpase1" className="collapse show">
                <div className="text-center auth-logo w-75 m-auto">
                  <a href="/">
                  <span>
                    <img src={logo} alt="" height="36"/>
                  </span>
                  </a>
                </div>
                <div className="text-center w-75 m-auto">
                  <p className="text-muted mb-4 mt-3">
                    Please enter your name and password to finish your account setup
                  </p>
                </div>
                {error ? (
                  <Alert color="danger" isOpen={!!error}>
                    <div>{errorMessage}</div>
                  </Alert>
                ) : (
                  success && (
                    <Alert color="success">
                      <div>{successMessage}</div>
                    </Alert>
                  )
                )}
                <Formik {...formikOptions}>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      {FieldConfigs.map((fieldConfig, configIndex) => (
                        <Col md={fieldConfig.colSpan || 12} key={`form-field-key-${configIndex}`}>
                          <DynamicFormikField
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            touched={touched}
                            errors={errors}
                            fieldConfig={fieldConfig}/>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col>
                        <Button color="primary" className="w-100" type="submit" disabled={isSubmitting}>
                          {!isSubmitting && <span>Accept Invitation</span>}
                          {isSubmitting && (
                            <span>Accepting Invitation<TinySpinner/></span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
})


export default AcceptInvitation
