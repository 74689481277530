import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {pick} from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  MANAGE_ACCEPT_INVITE_USER_MUTATION,
} from '../../../constants/mutations/manage'
import { ValidationSchema } from './AcceptInvitationFields'
import useHandleInitialInvitationData from './useHandleInitialInvitationData'
import { isUserAuthenticated, setSession } from '../../../helpers/authUtils'
import { AUTHENTICATE_USER_MUTATION } from '../../../constants/mutations/auth'
import GlobalContext from '../../../providers/GlobalContext'


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useAcceptInvitationForm() {

  const client = useApolloClient();
  const {setGlobalState, loggedInUser, clearSession} = useContext(GlobalContext);
  const {initialValues } = useHandleInitialInvitationData();
  const navigate = useNavigate();
  const timerRef = useRef(null);


  const [{ success, successMessage, error, errorMessage }, setState] = useReducer(reducer, {
    success: false,
  });

  useLayoutEffect(()=>{
    if (loggedInUser && clearSession){
      // alert('loggedInUser here')
     // clearSession();
    }
  },[loggedInUser, clearSession])

  const [acceptUserInvitation] = useMutation(MANAGE_ACCEPT_INVITE_USER_MUTATION)
  const [loginUser] = useMutation(AUTHENTICATE_USER_MUTATION);

  const processAcceptInvitation = useCallback(async function processAcceptInvitation(setSubmitting, finalData, email, password) {
    setSubmitting(true)
    const result = await acceptUserInvitation({ variables: finalData })
    setSubmitting(false)
    if (result && result.data && result.data.acceptUserInvitation) {
      if (result.data.acceptUserInvitation.success === true) {
        setState({
          error: false,
          errorMessage: undefined,
          success: true,
          successMessage: 'Your account setup is successful',
        });
        await client.clearStore();
        const result1 = await loginUser({ variables: {
            email, password
          }});
        setState({
          loading: false
        });
        if (result1 && result1.data){
          if (result1.data.authenticateUser){
            const data = result1.data.authenticateUser;
            if (data.success) {
              setSession(data);
              setGlobalState({
                loggedInUser: {...data.user}
              })
              const isAuthTokenValid = isUserAuthenticated();
              if(isAuthTokenValid){
                navigate('/');
              }
            } else {
              setSession(null)
            }
          }
        }
      } else {
        setState({
          error: true,
          errorMessage: Object.values(result.data)[0] && Object.values(result.data)[0].message ? Object.values(result.data)[0].message : 'Failed Request',
          success: false,
          successMessage: undefined,
        })
      }
    }
    // eslint-disable-next-line
  }, [acceptUserInvitation])

  const formikOptions = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: {
      email: initialValues.email || '',
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      password: initialValues.password || '',
      telephone: initialValues.telephone || '',
      rePassword: '',
      newPassword: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const finalData = pick(values, 'email', 'newPassword', 'firstName', 'lastName', 'telephone');
      const newPasswordStr = finalData.newPassword;
      finalData.password = initialValues.password;
      finalData.newPassword = btoa(finalData.newPassword);
      setState({
        error: false,
        errorMessage: undefined,
        success: false,
        successMessage: undefined,
      })
      try {
        await processAcceptInvitation(setSubmitting, finalData, finalData.email, newPasswordStr);
      } catch (err) {
        console.log(err)
        if (err && err.message) {
          setState({
            error: true,
            errorMessage: err.message,
          })
        }
      }
    },
  });


  useEffect(()=>{
    return ()=>{
      if(timerRef && timerRef.current){
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
    // eslint-disable-next-line
  }, [])

  return { success, successMessage, error, errorMessage, formikOptions }

}


export default useAcceptInvitationForm
