import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

const FieldConfigs = [{
  name: "firstName",
  label: "First Name",
  placeholder: "Enter first name",
  autoFocus: true,
  colSpan: 6
}, {
  name: "lastName",
  label: "Last Name",
  placeholder: "Enter last name",
  colSpan: 6
}, {
  name: "telephone",
  label: "Telephone",
  placeholder: "Enter telephone number",
  colSpan: 6
}];


const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required").max(50, "Must be less than 50 character."),
  lastName: Yup.string().required("Last name is required").max(50, "Must be less than 50 character."),
  telephone: Yup.string().test({
    name: "telephone",
    message: "Phone number is not valid",
    test: (val) => {
      if (val) {
        return isValidPhoneNumber(val, "GB");
      }
      return true;
    }
  })
  //Yup.string().matches(ukPhoneRegExp, 'Phone number is not valid').
});


export { FieldConfigs, ValidationSchema };
