import {
  ApolloClient,
  split,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { isNumber } from 'lodash'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
// eslint-disable-next-line
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import {
  GQL_API_URL,
  GQL_CLIENT_TIMEOUT,
  APP_GQL_WS_URL,
} from '../constants/env'
import {
  getAuthToken,
  getAuthTokenData, setSession,
} from '../helpers/authUtils'
import refreshAuthToken from './refreshAutthTokenUtil'

const timeoutLink = new ApolloLinkTimeout(GQL_CLIENT_TIMEOUT)


const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'token',
  isTokenValidOrUndefined: () => {
    const tokenData = getAuthTokenData();
    const { expiresIn: tokenExpiresIn, token, refreshToken } = tokenData;
    if (token && tokenExpiresIn && refreshToken) {
      const timeDiff = tokenExpiresIn * 1000 - Date.now();
      if (timeDiff < 5000) {
          return false
      }
    }
    return true
  },
  fetchAccessToken: () => {
    const tokenData = getAuthTokenData();
    const { token, refreshToken } = tokenData;
    return refreshAuthToken({ token, refreshToken })
  },
  handleResponse: (operation, accessTokenField) => (res) => {
    return { data: res }
  },
  handleError: (err)=>{
    if (err && err.message === 'UNAUTHORIZED'){
      setSession(null);
    }
  }
})

const fileUploadLink = createUploadLink({
  uri: () => {
    return `${GQL_API_URL}?_dc=${Date.now()}`
  },
  headers: {
    keepAlive: true,
  },
})

/*
uri: (opt)=>{
    return `${GQL_API_URL}?_dc=${Date.now()}`;
  },
 */

const wsLink = new GraphQLWsLink(createClient({
  url: APP_GQL_WS_URL,
  lazy: true,
  keepAlive: 1000 * 2,// ping server every 2 seconds
  retryAttempts: 10000000,// somewhere a large value
  shouldRetry: (errOrCloseEvent)=>{
    const token = getAuthToken();
    if (token) {
      return true;
    }
    return false;
  },
  on: {
    connected: (socket) => {
      //console.log('WebSocket connected');
    },
    connecting: ()=>{
     // console.log('WebSocket connecting');
    },
   /* ping: (received) => {
      console.log('ping');
    },
    pong: (received) => {
      console.log('pong')
    },*/
    closed: ()=>{
     // console.log('WebSocket disconnected');
    },
    error: (error)=>{
      //console.error('websocket received error', error);
    }
  },
  connectionParams:  () => {
    const token = getAuthToken()
    return {
      authToken: token,
    }
  }
}));

function incrementRetryAttemptContext(operation) {
  let _prevRetryAttempt = isNumber(operation.getContext()?.retryAttempt) ? operation.getContext()?.retryAttempt : 0
  _prevRetryAttempt++
  operation.setContext({
    retryAttempt: _prevRetryAttempt,
  })
  return operation
}


const errorLink = onError(({
                             response,
                             graphQLErrors,
                             networkError,
                             operation,
                             forward,
                           }) => {

  if (networkError) {
    // console.log('operation', operation);
    // console.log('networkError', networkError);
    incrementRetryAttemptContext(operation)
    try {
      const parsedErrorMsg = JSON.parse(networkError.bodyText)
      console.log('parsedErrorMsg', parsedErrorMsg)
    } catch (error) {
      networkError.message = networkError.bodyText
    }
    return forward(operation)
  }

  if (graphQLErrors) {
    incrementRetryAttemptContext(operation)
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, path }) => {
      console.log(`GraphQL Error: ${message}`, path && path.join('/'))
      if (message === 'UNAUTHORIZED' || message === 'Session expired') {
         setSession(null);
      }
    })
    //console.log('response', response);
    // console.log('operation', operation);
    // response.errors = undefined;
    return forward(operation)
  }
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-auth-token': getAuthToken(),
    },
  }))
  return forward(operation)
})

const retrylink = new RetryLink({
  delay: {
    initial: 300,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      // ServerError
      console.log('error at retryIf', error)
      console.log('_operation', _operation)
      // console.log('getContext', _operation.getContext())
      if (error?.message) {
        if (error?.message === 'Failed to fetch') {
          return true
        }
        if (/Knex: Timeout acquiring a connection/i.test(error?.message)) {
          return true
        }
      }
      return false
    },
  },
})

const httpLinks = ApolloLink.from([errorLink, retrylink, timeoutLink, tokenRefreshLink, authLink, fileUploadLink])

const splittedLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLinks,
)

const apolloClient = new ApolloClient({
  link: splittedLink,
  cache: new InMemoryCache({}),
})

export default apolloClient
