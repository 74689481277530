import {memoize} from 'lodash'

const extractFromUrl = memoize((param) => {
  return new URLSearchParams(window.location.search).get(param)
});

let AUTH_TOKEN, REFRESH_TOKEN, EXPIRES_IN;

/**
 * Returns the logged in user's token
 */
const getAuthToken = () => {
  const authToken = extractFromUrl('auth_token');
  if (authToken) {
    return authToken;
  }
  if (AUTH_TOKEN){
    return AUTH_TOKEN;
  }
  return localStorage.getItem('token')
}

const getTokenExpiresIn = () => {
  if (EXPIRES_IN){
    return EXPIRES_IN;
  }
  return localStorage.getItem('tokenExpiresIn')
}

const getRefreshToken = () => {
  if (REFRESH_TOKEN){
    return REFRESH_TOKEN;
  }
  return localStorage.getItem('refreshToken')
}

const setSession = data => {
  if (data) {
    AUTH_TOKEN = data.token;
    REFRESH_TOKEN = data.refreshToken;
    EXPIRES_IN = data.expiresIn;
    localStorage.setItem('token', data.token)
    localStorage.setItem('refreshToken', data.refreshToken)
    localStorage.setItem('tokenExpiresIn', data.expiresIn)
  }
  else {
    AUTH_TOKEN = undefined;
    REFRESH_TOKEN = undefined;
    EXPIRES_IN = undefined;
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExpiresIn')
  }
}

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const token = getAuthToken();
  if (!token) return false
  return true;
}


const getAuthTokenData = ()=>{
  return {
    token : getAuthToken(),
    refreshToken: getRefreshToken(),
    expiresIn: getTokenExpiresIn(),
  }

}


export {isUserAuthenticated, getAuthToken, setSession, getTokenExpiresIn, getRefreshToken, getAuthTokenData}
