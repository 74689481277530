import { gql } from '@apollo/client';

export const GET_LOGGED_IN_USER_QUERY = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      role
      companyId
      lastLoggedInCompanyId
      isEndUserAgreementAccepted
      passwordExpiresAt
      permissions {
        id
        companyId
      }
    }
  }
`;

export const GET_COMPANIES_QUERY = gql`
  query ($page: Int) {
    companies(page: $page) {
      pages
      count
      pageSize
      nodes {
        id
        name
        address1
        address2
        city
        country
        postcode
        stats {
          id
          devices {
            online
            offline
          }
          incidents {
            open
          }
        }
        sites {
          count
          nodes {
            id
            name
            stats {
              id
              devices {
                online
                offline
              }
              incidents {
                open
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_MINIMAL_QUERY = gql`
  query getCompanyMinimal($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      isLogisticsFeatureEnabled
      isHanwellAdapterEnabled
      isKubeVisionEnabled
      isLorawanEnabled
      geofenceRadius
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query ($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      isLogisticsFeatureEnabled
      isHanwellAdapterEnabled
      isKubeVisionEnabled
      isLorawanEnabled
      geofenceRadius
      stats {
        id
        devices {
          online
          offline
          totalCount
        }
        incidents {
          open
          closed
          unAcknowledged
          incomplete
        }
        systemCheck {
          weekly {
            incomplete
            total
          }
          intraday {
            incomplete
            total
          }
        }
      }
      userStats {
        activeUsers {
          count
        }
        inactiveUsers {
          count
        }
      }
    }
  }
`;

export const GET_COMPANY_OVERVIEW_QUERY = gql`
  query companyOverviewQuery($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      isLogisticsFeatureEnabled
      isHanwellAdapterEnabled
      isKubeVisionEnabled
      isLorawanEnabled
      geofenceRadius
      stats {
        id
        devices {
          online
          offline
          totalCount
        }
        incidents {
          open
          closed
          unAcknowledged
          incomplete
        }
      }
      assets {
        count
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_OVERVIEW_SITES_DETAILS_QUERY = gql`
  query companyOverviewSiteDetailsQuery($id: ID!) {
    company(id: $id) {
      id
      uuid
      sites {
        count
        nodes {
          id
          name
          address
          lat
          lng
          postcode
          assets {
            count
          }
          currentWeather {
            name
            main
            description
            icon
            tempNow
            tempMin
            tempMax
            pressure
            humidity
            windSpeed
            windDeg
            timestamp
            distance
          }
          zones {
            count
            nodes {
              id
              name
              stats {
                id
                incidents {
                  open
                }
              }
              assets {
                count
              }
            }
          }
          stats {
            id
            incidents {
              open
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_STATS_QUERY = gql`
  query companyStatsQuery($id: ID!) {
    company(id: $id) {
      id
      uuid
      stats {
        id
        devices {
          online
          offline
          totalCount
        }
        incidents {
          open
          closed
          unAcknowledged
          incomplete
        }
        systemCheck {
          siteStats {
            weekly {
              incomplete
              total
            }
            intraday {
              incomplete
              total
            }
          }
          zoneStats {
            weekly {
              incomplete
              total
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_LOGISTICS_QUERY = gql`
  query ($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      stats {
        id
        devices {
          online
          offline
          totalCount
        }
        drivers {
          count
        }
        vehicles {
          count
        }
        customers {
          count
        }
      }
      assets {
        count
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_FOR_INCIDENT_LIST_REPORT_QUERY = gql`
  query ($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      logoFile {
        id
        url
      }
      isLogisticsFeatureEnabled
    }
  }
`;

export const GET_COMPANY_COMPLIANCE_QUERY = gql`
  query ($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      address1
      address2
      city
      country
      postcode
      stats {
        id
        systemCheck {
          siteStats {
            weekly {
              incomplete
              total
            }
            intraday {
              incomplete
              total
            }
          }
          zoneStats {
            weekly {
              incomplete
              total
            }
            intraday {
              incomplete
              total
            }
          }
        }
      }
      sites {
        count
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_SITES_QUERY = gql`
  query ($page: Int, $companyId: ID) {
    sites(page: $page, companyId: $companyId) {
      pages
      count
      pageSize
      page
      nodes {
        id
        name
        address
        postcode
        lat
        lng
        defaultZoom
        stats {
          id
          devices {
            online
            offline
          }
          incidents {
            open
          }
        }
        assets {
          count
          nodes {
            id
            name
          }
        }
        zones {
          count
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_SITE_QUERY = gql`
  query ($id: ID!) {
    site(id: $id) {
      id
      name
      description
      address
      postcode
      lat
      lng
      defaultZoom
      company {
        id
        name
      }
      stats {
        id
        devices {
          online
          offline
        }
        incidents {
          open
          unAcknowledged
          incomplete
        }
      }
      zones {
        count
        nodes {
          id
          name
          stats {
            id
            devices {
              online
              offline
            }
            incidents {
              open
            }
          }
          assets {
            count
          }
        }
      }
      devices {
        count
        nodes {
          id
          name
          stats {
            id
            alarms {
              open
            }
          }
        }
      }
      assets {
        count
        nodes {
          id
          name
        }
      }
      userStats {
        activeUsers {
          count
        }
        inactiveUsers {
          count
        }
      }
      currentWeather {
        name
        main
        description
        icon
        tempNow
        tempMin
        tempMax
        pressure
        humidity
        windSpeed
        windDeg
        timestamp
        distance
      }
    }
  }
`;

export const GET_ZONES_QUERY = gql`
  query ($page: Int, $companyId: ID) {
    zones(page: $page, companyId: $companyId) {
      id
      name
    }
  }
`;

export const GET_ZONE_QUERY = gql`
  query getZone($id: ID!) {
    zone(id: $id) {
      id
      name
      type
      floorPlanFile {
        id
        url
      }
      company {
        id
        name
      }
      site {
        id
        name
        lat
        lng
        defaultZoom
        currentWeather {
          name
          main
          description
          icon
          tempNow
          tempMin
          tempMax
          pressure
          humidity
          windSpeed
          windDeg
          timestamp
          distance
        }
      }
      assets {
        count
        nodes {
          id
          name
        }
      }
      stats {
        id
        incidents {
          open
          unAcknowledged
          incomplete
        }
      }
    }
  }
`;

export const GET_ZONE_ASSETS_STATS_QUERY = gql`
  query ($id: ID!, $page: Int) {
    zone(id: $id) {
      id
      assets(page: $page) {
        count
        pages
        page
        nodes {
          id
          name
          type
          atDepotSince
          lastDepot {
            id
            name
            lat
            lng
          }
          lastLocation {
            lat
            lng
            time
          }
          stats {
            id
            devices {
              online
              offline
              location {
                last {
                  lat
                  lng
                  time
                }
              }
              temperature {
                last {
                  channel
                  value
                  time
                }
              }
              humidity {
                last {
                  value
                  time
                }
              }
              battery {
                last {
                  value
                  time
                }
              }
              signal {
                last {
                  value
                  time
                }
              }
              movement {
                last {
                  value
                  time
                }
              }
              direction {
                last {
                  value
                  time
                }
              }
              ignition {
                last {
                  value
                  time
                }
              }
              speed {
                last {
                  value
                  time
                }
              }
            }
            incidents {
              open
              unAcknowledged
            }
          }
        }
      }
    }
  }
`;

export const GET_DEVICE_QUERY = gql`
  query ($id: ID!, $reportOnly: Boolean) {
    device(id: $id) {
      id
      name
      serial
      outOfServiceAt
      deviceType {
        id
        name
        properties {
          id
          name
          unit
        }
      }
      propertyLastStats {
        propertyGroupName
        propertyGroupType
        time
        unit
        value
      }
      isAtDepot {
        time
        value
      }
      assets {
        id
        uuid
        name
        type
      }
      assetProperties(reportOnly: $reportOnly) {
        id
        name
        assetId
        assetName
        unit
        deviceName
        deviceId
        deviceUuid
        deviceSerial
        propertyId
        groupId
        property {
          id
          name
          unit
        }
        propertyGroup {
          id
          uuid
          name
          isShowInReport
          optimalLow
          optimalHigh
          alarmLow
          alarmHigh
          isIncident
          waitOpen
          waitClose
          deadman
          type
        }
      }
      configurations {
        id
        name
        data
      }
    }
  }
`;

export const GET_ASSET_QUERY = gql`
  query ($id: ID!) {
    asset(id: $id) {
      id
      name
      uuid
      type
      notificationProfilesCount
      outOfServiceAt
      zone {
        id
        name
      }
      company {
        id
        name
      }
      site {
        id
        name
      }
      devices {
        id
        name
      }
      last24HOptimalPercentage
      last24HCompletePercentage
      atDepotSince
      lastDepot {
        id
        name
        lat
        lng
      }
      lastLocation {
        lat
        lng
        time
      }
      deviceProperties(reportOnly: true) {
        id
        name
        unit
        assetId
        deviceName
        deviceId
        deviceSerial
        deviceUuid
        outOfServiceAt
        propertyId
        groupId
        property {
          id
          name
          unit
        }
        propertyGroup {
          id
          uuid
          name
          isShowInReport
          optimalLow
          optimalHigh
          alarmLow
          alarmHigh
          isIncident
          waitOpen
          waitClose
          deadman
          type
        }
      }
      devicePropertiesHistory(reportOnly: true) {
        id
        name
        unit
        assetId
        deviceName
        deviceId
        deviceUuid
        deviceSerial
        outOfServiceAt
        propertyId
        groupId
        property {
          id
          name
          unit
        }
        propertyGroup {
          id
          name
          isShowInReport
          optimalLow
          optimalHigh
          alarmLow
          alarmHigh
          isIncident
          waitOpen
          waitClose
          deadman
          type
        }
      }
      stats {
        id
        incidents {
          id
          open
          unAcknowledged
          incomplete
        }
      }
      currentWeather {
        name
        main
        description
        icon
        tempNow
        tempMin
        tempMax
        pressure
        humidity
        windSpeed
        windDeg
        timestamp
        distance
      }
    }
  }
`;

export const GET_ASSET_PROPERTY_LAST_STATS_QUERY = gql`
  query getAssetPropStats($id: ID!) {
    asset(id: $id) {
      id
      devicePropertyLastStats {
        propertyGroupName
        propertyGroupType
        time
        unit
        value
        status
      }
    }
  }
`;

export const GET_ASSET_MINIMAL_QUERY = gql`
  query getAssetMinimal($id: ID!) {
    asset(id: $id) {
      id
      uuid
      name
    }
  }
`;

export const GET_ASSET_DEVICE_PROPERTY_SUMMARIES = gql`
  query ($id: ID!, $from: String!, $to: String) {
    asset(id: $id) {
      id
      devicePropertySummary(from: $from, to: $to) {
        name
        avg
        min
        max
        stdDev
        mkt
        last
        lastTime
        unit
        deviceName
        deviceTypeName
        propertyGroupName
        propertyGroupType
        propertyGroupId
        propertyId
        serial
        deviceUuid
        deviceId
        outOfServiceAt
      }
    }
  }
`;

export const GET_INCIDENT_QUERY = gql`
  query incidentDetails($id: ID!) {
    incident(id: $id) {
      id
      assetName
      assetId
      assetType
      propertyId
      propertyGroupName
      propertyGroupType
      groupId
      type
      worstValue
      unit
      status
      progress
      isAcknowledged
      acknowledgedBy
      acknowledgedByUser {
        id
        email
        firstName
        lastName
      }
      acknowledgedByContactId
      acknowledgedByContact {
        id
        name
      }
      acknowledgedAt
      openedAt
      createdAt
      closedAt
      property {
        id
        name
      }
      propertyGroup {
        name
        optimalLow
        optimalHigh
        alarmLow
        alarmHigh
      }
      actionsRequired {
        ack {
          required
          actionedAt
        }
        root {
          required
          actionedAt
        }
        corrective {
          required
          actionedAt
        }
        preventative {
          required
          actionedAt
        }
      }
      propertyStatus {
        propertyName
        deviceId
        deviceName
        propertyValue
        unit
        status
        time
      }
      acknowledgedByControlDeviceId
      acknowledgedByControlDevice {
        id
        controlDeviceName
      }
    }
  }
`;

export const GET_INCIDENT_EVENTS_QUERY = gql`
  query incidentEvents(
    $page: Int!
    $incidentId: ID
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    events(
      page: $page
      incidentId: $incidentId
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        title
        property_name
        description
        time
        metadata {
          name
          value
        }
        incidentRootCauseTypeId
        incidentRootCauseType {
          id
          name
        }
        incidentCorrectiveActionTypeId
        incidentCorrectiveActionType {
          id
          name
        }
        incidentPreventiveActionTypeId
        incidentPreventiveActionType {
          id
          name
        }
      }
    }
  }
`;

export const GET_ASSET_INCIDENT_EVENTS_QUERY = gql`
  query assetIncidentEvents(
    $page: Int!
    $assetId: ID
    $companyId: ID
    $from: String
    $to: String
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    events(
      page: $page
      assetId: $assetId
      companyId: $companyId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        title
        property_name
        incident_id
        description
        time
        incident {
          id
          worstValue
          unit
          type
          status
        }
      }
    }
  }
`;

export const GET_INCIDENT_ASSET_DEVICE_PROPERTY_SUMMARIES = gql`
  query ($id: ID!) {
    incident(id: $id) {
      id
      assetDeviceProperties {
        id
        name
        unit
        assetId
        deviceName
        deviceId
        deviceSerial
        deviceUuid
        outOfServiceAt
        propertyId
        groupId
        property {
          id
          name
          unit
        }
        propertyGroup {
          id
          uuid
          name
          isShowInReport
          optimalLow
          optimalHigh
          alarmLow
          alarmHigh
          isIncident
          waitOpen
          waitClose
          deadman
          type
        }
      }
      assetDevicePropertySummary {
        name
        avg
        min
        max
        stdDev
        mkt
        last
        lastTime
        unit
        deviceName
        deviceTypeName
        propertyGroupName
        propertyGroupType
        propertyGroupId
        propertyId
        serial
        deviceUuid
        deviceId
        outOfServiceAt
      }
    }
  }
`;

export const GET_INCIDENTS_QUERY = gql`
  query incidents(
    $page: Int!
    $assetId: ID
    $companyId: ID
    $siteId: ID
    $zoneId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
    $from: String
    $to: String
  ) {
    incidents(
      page: $page
      assetId: $assetId
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        assetName
        assetId
        assetType
        propertyGroupName
        propertyGroupType
        type
        worstValue
        unit
        status
        progress
        isAcknowledged
        acknowledgedByUser {
          id
          email
          firstName
          lastName
        }
        acknowledgedByContactId
        acknowledgedByContact {
          id
          name
        }
        acknowledgedByControlDeviceId
        acknowledgedByControlDevice {
          id
          controlDeviceName
        }
        actionsRequired {
          ack {
            required
            actionedAt
          }
          root {
            required
            actionedAt
          }
          corrective {
            required
            actionedAt
          }
          preventative {
            required
            actionedAt
          }
        }
        openedAt
        createdAt
        closedAt
        duration
        asset {
          id
          name
          zone {
            id
            name
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_INCIDENTS_FOR_COMPLIANCE_QUERY = gql`
  query incidentsForCompliance(
    $page: Int!
    $assetId: ID
    $companyId: ID
    $siteId: ID
    $zoneId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
    $from: String
    $to: String
  ) {
    incidentsForCompliance(
      page: $page
      assetId: $assetId
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        assetName
        assetId
        assetType
        propertyGroupName
        propertyGroupType
        type
        worstValue
        unit
        status
        progress
        isAcknowledged
        acknowledgedByUser {
          firstName
          lastName
        }
        acknowledgedByContactId
        acknowledgedByContact {
          id
          name
        }
        openedAt
        createdAt
        closedAt
        duration
        actionsRequired {
          ack {
            required
            actionedAt
          }
          root {
            required
            actionedAt
          }
          corrective {
            required
            actionedAt
          }
          preventative {
            required
            actionedAt
          }
        }
        asset {
          id
          name
          zone {
            id
            name
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_INCIDENTS_PERFORMANCE_QUERY = gql`
  query (
    $companyId: ID
    $assetId: ID
    $siteId: ID
    $zoneId: ID
    $from: String
    $to: String
  ) {
    incidentPerformance(
      assetId: $assetId
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      from: $from
      to: $to
    ) {
      count
      stats {
        type
        count
      }
      avgDuration
      averageAcknowledgementTime
    }
  }
`;

export const GET_EVENTS_QUERY = gql`
  query (
    $page: Int!
    $companyId: ID!
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    events(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        incident_id
        asset_name
        property_name
        group_name
        description
        time
      }
    }
  }
`;

export const GET_DEVICES_QUERY = gql`
  query (
    $page: Int
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    devices(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        serial
        uuid
        assetName
        siteName
        zoneName
        deviceType {
          id
          name
        }
        lastReportedAt
        outOfServiceAt
        propertyLastStats {
          name
          isShowInReport
          propertyGroupName
          propertyGroupType
          time
          unit
          value
          status
        }
        certificateExpireDate
        assets {
          id
          name
          type
        }
        isAtDepot {
          time
          value
        }
      }
    }
  }
`;

export const GET_DEVICE_PROFILES_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $groupId: ID
    $from: String!
    $to: String
  ) {
    device(id: $id) {
      id
      uuid
      name
      serial
      chart(channels: $channels, groupId: $groupId, from: $from, to: $to) {
        nodes {
          name
          propertyGroupName
          deviceName
          deviceId
          deviceSerial
          unit
          avg
          min
          max
          time
        }
      }
    }
  }
`;

export const GET_DEVICE_PROFILES_RAW_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $groupId: ID
    $from: String!
    $to: String
    $page: Int!
  ) {
    device(id: $id) {
      id
      uuid
      name
      serial
      chartRaw(
        channels: $channels
        groupId: $groupId
        from: $from
        to: $to
        page: $page
      ) {
        count
        page
        pages
        nodes {
          name
          propertyGroupName
          propertyId
          propertyGroupId
          assetId
          assetName
          deviceName
          deviceId
          unit
          value
          time
        }
      }
    }
  }
`;

export const GET_DEVICE_PROPERTY_SUMMARIES = gql`
  query ($id: ID!, $from: String!, $to: String, $reportOnly: Boolean) {
    device(id: $id) {
      id
      uuid
      name
      serial
      devicePropertySummary(from: $from, to: $to, reportOnly: $reportOnly) {
        name
        stdDev
        mkt
        avg
        min
        max
        last
        lastTime
        unit
        deviceName
        deviceTypeName
        serial
        deviceUuid
        deviceId
        propertyGroupName
        propertyGroupId
        propertyId
      }
    }
  }
`;

export const GET_DEVICE_PERFORMANCE_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!, $reportOnly: Boolean) {
    device(id: $id) {
      id
      uuid
      name
      serial
      devicePerformances(from: $from, to: $to, reportOnly: $reportOnly) {
        deviceUuid
        deviceName
        propertyId
        propertyName
        propertyGroupId
        propertyGroupName
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        isReportOnly
      }
    }
  }
`;

export const GET_DEVICE_DATA_COMPLETENESS_METRIC_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    device(id: $id) {
      id
      uuid
      dataCompletenessMetrics(from: $from, to: $to) {
        deviceUuid
        assetId
        assetName
        propertyGroupId
        propertyId
        propertyName
        propertyGroupName
        totalCount
        completeCount
        completePercentage
      }
    }
  }
`;

export const GET_DEVICE_PROPERTY_LAST_STATS_QUERY = gql`
  query getDevicePropStats($id: ID!) {
    device(id: $id) {
      id
      name
      uuid
      serial
      propertyLastStats {
        propertyGroupName
        propertyGroupType
        time
        unit
        value
        unit
        status
      }
    }
  }
`;

export const GET_ASSETS_QUERY = gql`
  query (
    $page: Int
    $companyId: ID
    $siteId: ID
    $zoneId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    assets(
      page: $page
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        uuid
        name
        type
        deviceCount
        incidentCount
        lastReportedAt
        outOfServiceAt
        last24HOptimalPercentage
        last24HCompletePercentage
        site {
          id
          name
        }
        zone {
          id
          name
        }
        deviceProperties(reportOnly: true) {
          id
          name
          unit
          deviceName
          deviceId
          deviceSerial
          propertyId
          groupId
          outOfServiceAt
        }
        devicePropertyLastStats {
          propertyGroupName
          propertyGroupType
          time
          unit
          value
          status
        }
        atDepotSince
        lastDepot {
          id
          name
          lat
          lng
        }
        lastLocation {
          lat
          lng
          time
        }
        stats {
          id
          devices {
            id
            online
            offline
          }
          incidents {
            id
            open
          }
        }
        devicePropertySummaryLastValueOnly(
          from: "now-24h"
          to: "now"
          includeHistory: false
        ) {
          name
          last
          lastTime
          unit
          deviceName
          deviceTypeName
          propertyGroupName
          propertyGroupType
          propertyGroupId
          propertyId
          serial
          deviceUuid
          deviceId
        }
      }
    }
  }
`;

export const GET_VEHICLES_QUERY = gql`
  query (
    $page: Int
    $companyId: ID
    $siteId: ID
    $zoneId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    assets(
      page: $page
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        type
        deviceCount
        incidentCount
        site {
          id
          name
        }
        zone {
          id
          name
        }
        deviceProperties(reportOnly: true) {
          id
          name
          unit
          deviceName
          deviceId
          deviceSerial
          propertyId
          groupId
          outOfServiceAt
        }
        devicePropertyLastStats {
          propertyGroupName
          propertyGroupType
          time
          unit
          value
          status
        }
        lastReportedAt
        atDepotSince
        lastDepot {
          id
          name
          lat
          lng
        }
        lastLocation {
          lat
          lng
          time
        }
        stats {
          id
          devices {
            id
            online
            offline
            location {
              last {
                lat
                lng
                time
              }
            }
            temperature {
              last {
                channel
                value
                time
              }
            }
            humidity {
              last {
                value
                time
              }
            }
            battery {
              last {
                value
                time
              }
            }
            signal {
              last {
                value
                time
              }
            }
            movement {
              last {
                value
                time
              }
            }
            direction {
              last {
                value
                time
              }
            }
            ignition {
              last {
                value
                time
              }
            }
            speed {
              last {
                value
                time
              }
            }
          }
          incidents {
            id
            open
            unAcknowledged
          }
        }
      }
    }
  }
`;

export const GET_ZONE_FLOOR_PLAN_ANNOTATIONS_QUERY = gql`
  query ($id: ID!) {
    zone(id: $id) {
      id
      name
      type
      floorPlanFile {
        id
        url
      }
      zoneFloorPlanAnnotations {
        id
        assetId
        assetName
        deviceId
        deviceName
        deviceSerial
        isGroup
        groupId
        groupName
        propertyName
        propertyId
        unit
        positionX
        positionY
      }
    }
  }
`;

export const GET_ZONE_FLOOR_PLAN_ANNOTATIONS_DATA_QUERY = gql`
  query ($id: ID!) {
    zone(id: $id) {
      id
      name
      type
      floorPlanFile {
        id
        url
      }
      zoneFloorPlanAnnotationsData {
        id
        assetId
        assetName
        deviceId
        deviceName
        deviceSerial
        isGroup
        groupId
        groupName
        propertyGroupType
        propertyGroupOptimalLow
        propertyGroupOptimalHigh
        propertyGroupAlarmLow
        propertyGroupAlarmHigh
        propertyName
        propertyId
        unit
        positionX
        positionY
        value
        time
        status
      }
    }
  }
`;

export const GET_ZONE_ASSETS_LIST_QUERY = gql`
  query zonesAssetLists(
    $page: Int
    $companyId: ID
    $siteId: ID
    $zoneId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    assets(
      page: $page
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        type
        deviceCount
        devicePropertiesWithLastValue(reportOnly: true) {
          id
          name
          value
          time
          unit
          status
          deviceName
          deviceId
          deviceSerial
          propertyId
          groupId
          propertyGroup {
            id
            name
            type
            alarmLow
            alarmHigh
            optimalLow
            optimalHigh
            isShowInReport
          }
        }
      }
    }
  }
`;

export const GET_TELEMETRY_QUERY = gql`
  query (
    $id: ID!
    $page: Int!
    $channel: String
    $from: String!
    $to: String
    $assetUuid: String
  ) {
    device(id: $id) {
      id
      name
      uuid
      serial
      telemetry(
        page: $page
        channel: $channel
        from: $from
        to: $to
        assetUuid: $assetUuid
      ) {
        pages
        count
        nodes {
          name
          unit
          value
          time
        }
      }
    }
  }
`;

export const GET_JOURNEY_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String, $page: Int) {
    device(id: $id) {
      id
      name
      serial
      uuid
      journey(from: $from, to: $to, page: $page) {
        count
        page
        pages
        nodes {
          name
          value
          unit
          time
          deviceUuid
        }
      }
    }
  }
`;

export const GET_ASSET_JOURNEY_QUERY = gql`
  query assetJourney(
    $id: ID!
    $from: String!
    $to: String
    $page: Int
    $includeEvents: Boolean
  ) {
    asset(id: $id) {
      id
      name
      uuid
      journey(
        from: $from
        to: $to
        page: $page
        includeEvents: $includeEvents
      ) {
        count
        page
        pages
        nodes {
          name
          value
          unit
          time
          deviceUuid
        }
      }
    }
  }
`;

export const GET_ASSET_TELEMETRY_EVENTS_QUERY = gql`
  query assetTelemetryEvents(
    $id: ID!
    $from: String!
    $to: String
    $page: Int
  ) {
    asset(id: $id) {
      id
      name
      uuid
      telemetryEvents(from: $from, to: $to, page: $page) {
        count
        page
        pages
        nodes {
          name
          value
          unit
          time
          deviceUuid
        }
      }
    }
  }
`;

export const GET_ASSET_LIVE_STREAM_DEVICES_QUERY = gql`
  query assetLiveStreamDevices($id: ID!, $page: Int) {
    asset(id: $id) {
      id
      name
      uuid
      liveStreamDevices(page: $page) {
        count
        page
        pages
        nodes {
          id
          uuid
          name
          companyId
          configurations {
            id
            data
          }
        }
      }
    }
  }
`;

export const GET_TRIPS_QUERY = gql`
  query assetTrips(
    $assetId: ID
    $page: Int
    $companyId: ID!
    $driverId: ID
    $customerId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    trips(
      page: $page
      companyId: $companyId
      assetId: $assetId
      driverId: $driverId
      customerId: $customerId
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        driverId
        vehicleId
        startedAt
        endedAt
        stopTime
        totalDuration
        distanceCovered
        topSpeed
        avgSpeed
        detailedStartAddress
        detailedEndAddress
        nearestWaypointId
        driverRating
        driver {
          id
          firstName
          lastName
        }
        nearestWayPoint {
          id
          name
        }
      }
    }
  }
`;

export const GET_TRIP_QUERY = gql`
  query trip($id: ID!) {
    trip(id: $id) {
      id
      driverId
      vehicleId
      startedAt
      endedAt
      stopTime
      totalDuration
      distanceCovered
      topSpeed
      avgSpeed
      detailedStartAddress
      detailedEndAddress
      nearestWaypointId
      driverRating
      asset {
        id
        name
        uuid
        type
        site {
          id
          name
        }
        zone {
          id
          name
        }
      }
      driver {
        id
        firstName
        lastName
      }
      nearestWayPoint {
        id
        name
        name
        address
        lat
        lng
      }
    }
  }
`;

export const GET_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $from: String!
    $to: String
    $assetUuid: String
  ) {
    device(id: $id) {
      id
      name
      serial
      chart(channels: $channels, from: $from, to: $to, assetUuid: $assetUuid) {
        nodes {
          name
          propertyGroupName
          unit
          avg
          min
          max
          time
        }
      }
    }
  }
`;

export const GET_DEVICE_RAW_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $groupId: ID
    $assetUuid: String
    $from: String!
    $to: String
    $page: Int!
  ) {
    device(id: $id) {
      id
      name
      serial
      chartRaw(
        channels: $channels
        groupId: $groupId
        assetUuid: $assetUuid
        from: $from
        to: $to
        page: $page
      ) {
        count
        page
        pages
        nodes {
          name
          propertyGroupName
          propertyId
          propertyGroupId
          assetId
          assetName
          deviceName
          deviceId
          unit
          value
          time
        }
      }
    }
  }
`;

export const GET_ASSET_PROFILES_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $groupId: ID
    $from: String!
    $to: String
  ) {
    asset(id: $id) {
      id
      name
      chart(channels: $channels, groupId: $groupId, from: $from, to: $to) {
        nodes {
          name
          deviceName
          deviceId
          deviceSerial
          propertyGroupName
          unit
          avg
          min
          max
          time
        }
      }
    }
  }
`;

export const GET_ASSET_PROFILES_RAW_CHART_QUERY = gql`
  query (
    $id: ID!
    $channels: [String]
    $groupId: ID
    $from: String!
    $to: String
    $page: Int!
  ) {
    asset(id: $id) {
      id
      name
      chartRaw(
        channels: $channels
        groupId: $groupId
        from: $from
        to: $to
        page: $page
      ) {
        count
        page
        pages
        nodes {
          name
          propertyGroupName
          deviceName
          deviceId
          unit
          value
          time
        }
      }
    }
  }
`;

export const GET_ASSET_WEATHER_HISTORY_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    asset(id: $id) {
      id
      name
      type
      uuid
      weatherHistory(from: $from, to: $to) {
        name
        main
        description
        icon
        tempNow
        tempMin
        tempMax
        pressure
        humidity
        windSpeed
        windDeg
        timestamp
        distance
      }
    }
  }
`;

export const GET_REPORTS_QUERY = gql`
  query reports(
    $companyId: ID!
    $page: Int! = 1
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    reports(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      pages
      count
      page
      pageSize
      nodes {
        name
        id
        uuid
        title
        type
        reportType
        fromDate
        toDate
        asset {
          id
          name
        }
        site {
          id
          name
        }
        zone {
          id
          name
        }
        files {
          id
          name
          originalName
          contentType
          category
        }
      }
    }
  }
`;

export const GET_ASSET_PDF_REPORTS = gql`
  query ($assetId: ID!, $page: Int! = 1) {
    assetPdfReports(page: $page, assetId: $assetId) {
      pages
      count
      page
      pageSize
      nodes {
        name
        id
        uuid
        title
        type
        fromDate
        toDate
        files {
          id
          name
          originalName
          contentType
          category
        }
      }
    }
  }
`;

export const GET_ZONE_PDF_REPORTS = gql`
  query ($zoneId: ID!, $page: Int! = 1) {
    zonePdfReports(page: $page, zoneId: $zoneId) {
      pages
      count
      page
      pageSize
      nodes {
        name
        id
        uuid
        title
        type
        fromDate
        toDate
        files {
          id
          name
          originalName
          contentType
          category
        }
      }
    }
  }
`;

export const GET_SITE_PDF_REPORTS = gql`
  query ($siteId: ID!, $page: Int! = 1) {
    sitePdfReports(page: $page, siteId: $siteId) {
      pages
      count
      page
      pageSize
      nodes {
        name
        id
        uuid
        title
        type
        fromDate
        toDate
        files {
          id
          name
          originalName
          contentType
          category
        }
      }
    }
  }
`;

export const GET_REPORT_FILE_SIGNED_READ_URL = gql`
  query reportFileSignedUrl($id: ID!, $isDownload: Boolean) {
    report(id: $id) {
      id
      readableFiles(isDownload: $isDownload) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;
export const GET_REPORT_FILE_SIGNED_DOWNLOAD_URL = gql`
  query reportFileSignedUrl($id: ID!) {
    report(id: $id) {
      id
      readableFiles(isDownload: true) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_ASSET_FILE_SIGNED_READ_URL = gql`
  query assetFileSignedUrl($id: ID!, $fileId: ID!, $isDownload: Boolean) {
    asset(id: $id) {
      id
      readableFile(fileId: $fileId, isDownload: $isDownload) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_ASSET_DEVICE_CERTIFICATE_FILE_SIGNED_READ_URL = gql`
  query readableDeviceCertificateFile(
    $id: ID!
    $fileId: ID!
    $isDownload: Boolean
  ) {
    asset(id: $id) {
      id
      readableDeviceCertificateFile(fileId: $fileId, isDownload: $isDownload) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_ASSET_FILE_SIGNED_DOWNLOAD_URL = gql`
  query assetFileSignedDownloadUrl($id: ID!, $fileId: ID!) {
    asset(id: $id) {
      id
      readableFile(fileId: $fileId, isDownload: true) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_EVENT_FILTERS_QUERY = gql`
  query manageEventFilters($companyId: ID!, $pageSize: Int) {
    assets(companyId: $companyId, pageSize: $pageSize) {
      nodes {
        id
        name
      }
    }
    devicePropertyGroups(companyId: $companyId, pageSize: $pageSize) {
      nodes {
        id
        name
      }
    }
    deviceProperties(companyId: $companyId, pageSize: $pageSize) {
      nodes {
        id
        name
      }
    }
  }
`;

export const GET_ASSET_PERFORMANCE_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    asset(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        deviceUuid
        deviceName
        propertyName
        propertyGroupId
        propertyId
        propertyGroupName
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_ASSET_DATA_COMPLETENESS_METRIC_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    asset(id: $id) {
      id
      dataCompletenessMetrics(from: $from, to: $to) {
        deviceId
        deviceUuid
        deviceName
        propertyGroupId
        propertyId
        propertyName
        propertyGroupName
        totalCount
        completeCount
        completePercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_ZONE_PERFORMANCE_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    zone(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        propertyName
        propertyGroupName
        assetName
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_COMPANY_PERFORMANCE_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    company(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        propertyName
        propertyGroupName
        siteName
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_SITE_PERFORMANCE_QUERY = gql`
  query ($id: ID!, $from: String!, $to: String!) {
    site(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        propertyName
        propertyGroupName
        zoneName
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_GLOBAL_SEARCH_QUERY = gql`
  query globalSearch($companyId: ID!, $search: String!) {
    globalSearch(search: $search, companyId: $companyId) {
      id
      companyId
      name
      type
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      telephone
      profilePhotoFileId
      profilePictureFile {
        id
        url
      }
    }
  }
`;

export const GET_DRIVERS_QUERY = gql`
  query drivers(
    $page: Int
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    drivers(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        companyId
        telephone
        asset {
          id
          name
          type
          isAtDepot {
            time
            value
          }
          stats {
            id
            devices {
              id
              location {
                last {
                  lat
                  lng
                  time
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DRIVER_QUERY = gql`
  query driver($id: ID!) {
    driver(id: $id) {
      id
      firstName
      lastName
      email
      telephone
      companyId
      profilePictureFileId
      profilePictureFile {
        id
        url
      }
      company {
        id
        name
      }
      stats {
        rating
        totalTripCount
        avgStopTime
        lastTrip {
          id
          startedAt
          endedAt
        }
      }
      asset {
        id
        name
        type
        isAtDepot {
          time
          value
        }
      }
    }
  }
`;

export const GET_JOURNEY_TRIP_EVENTS_QUERY = gql`
  query journeyTripEvents(
    $tripId: ID
    $page: Int
    $companyId: ID!
    $assetId: ID
    $driverId: ID
    $customerId: ID
    $from: String
    $to: String
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    tripEvents(
      page: $page
      pageSize: 500
      events: ["speeding"]
      companyId: $companyId
      tripId: $tripId
      assetId: $assetId
      driverId: $driverId
      customerId: $customerId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        description
        time
        assetId
        metadata {
          name
          time
          address
          speedLimit
          actualSpeed
          vehicleId
          driverId
          gps {
            lat
            lon
          }
        }
      }
    }
  }
`;

export const GET_TRIP_EVENTS_QUERY = gql`
  query tripEvents(
    $tripId: ID
    $page: Int
    $companyId: ID!
    $driverId: ID
    $customerId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    tripEvents(
      page: $page
      companyId: $companyId
      tripId: $tripId
      driverId: $driverId
      customerId: $customerId
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        description
        time
        metadata {
          name
          time
          address
          speedLimit
          actualSpeed
          gps {
            lat
            lon
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_WAYPOINTS_QUERY = gql`
  query (
    $page: Int
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    customerWaypoints(
      page: $page
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        address
        lat
        lng
        website
        telephone
        postcode
        defaultZoom
        stats {
          avgStopTime
        }
      }
    }
  }
`;

export const GET_CUSTOMER_WAYPOINT_QUERY = gql`
  query ($id: ID!) {
    customerWaypoint(id: $id) {
      id
      name
      address
      lat
      lng
      website
      telephone
      postcode
      defaultZoom
      stats {
        totalTripCount
        avgStopTime
        lastTrip {
          id
          startedAt
          endedAt
        }
      }
      company {
        id
        name
      }
    }
  }
`;

export const GET_ASSET_RAW_TELEMETRY_QUERY = gql`
  query assetTelemetryRawData(
    $page: Int
    $companyId: ID!
    $assetIds: [ID!]!
    $from: String!
    $to: String!
  ) {
    assetTelemetryRawData(
      page: $page
      companyId: $companyId
      assetIds: $assetIds
      from: $from
      to: $to
    ) {
      count
      pages
      page
      pageSize
      nodes {
        assetName
        deviceName
        deviceSerial
        groupName
        propertyName
        unit
        value
        time
      }
    }
  }
`;

export const GET_SITE_SUMMARY_TELEMETRY_QUERY = gql`
  query siteSummaryCsvData(
    $companyId: ID!
    $zoneIds: [ID!]!
    $from: String!
    $to: String!
  ) {
    siteSummaryCsvData(
      companyId: $companyId
      zoneIds: $zoneIds
      from: $from
      to: $to
    ) {
      assetName
      deviceName
      deviceSerial
      propertyName
      propertyGroupName
      unit
      avg
      min
      max
      stdDev
      mkt
      last
    }
  }
`;

export const GET_SITE_ASSET_SUMMARY_PERFORMANCE_TELEMETRY_QUERY = gql`
  query siteAssetSummaryPerformanceCsvData(
    $companyId: ID!
    $zoneIds: [ID!]!
    $from: String!
    $to: String!
  ) {
    siteAssetSummaryPerformanceCsvData(
      companyId: $companyId
      zoneIds: $zoneIds
      from: $from
      to: $to
    ) {
      assetName
      siteName
      zoneName
      deviceName
      deviceSerial
      propertyName
      propertyGroupName
      unit
      avg
      min
      max
      stdDev
      mkt
      last
      optimalBelowLowCount
      optimalCount
      optimalAboveHighCount
      totalCount
      optimalBelowLowPercentage
      optimalPercentage
      optimalAboveHighPercentage
      incidentBelowLowCount
      incidentAboveHighCount
      incidentBelowLowPercentage
      incidentAboveHighPercentage
    }
  }
`;
export const GET_SITE_PERFORMANCE_CSV_QUERY = gql`
  query sitePerformanceCsvData(
    $companyId: ID!
    $zoneIds: [ID!]!
    $from: String!
    $to: String!
  ) {
    sitePerformanceCsvData(
      companyId: $companyId
      zoneIds: $zoneIds
      from: $from
      to: $to
    ) {
      assetName
      siteName
      zoneName
      deviceName
      deviceSerial
      propertyName
      propertyGroupName
      optimalBelowLowCount
      optimalCount
      optimalAboveHighCount
      totalCount
      optimalBelowLowPercentage
      optimalPercentage
      optimalAboveHighPercentage
      incidentBelowLowCount
      incidentAboveHighCount
      incidentBelowLowPercentage
      incidentAboveHighPercentage
    }
  }
`;

export const GET_INCIDENTS_CSV_QUERY = gql`
  query incidentsForCsvReport(
    $page: Int!
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
    $from: String
    $to: String
  ) {
    incidentsForCsvReport(
      page: $page
      companyId: $companyId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        assetName
        assetId
        assetType
        propertyGroupName
        propertyGroupType
        type
        worstValue
        unit
        status
        progress
        isAcknowledged
        acknowledgedByUser {
          id
          email
          firstName
          lastName
        }
        acknowledgedByContactId
        acknowledgedByContact {
          id
          name
        }
        acknowledgedByControlDeviceId
        acknowledgedByControlDevice {
          id
          controlDeviceName
        }
        acknowledgedAt
        actionsRequired {
          ack {
            required
            actionedAt
          }
          root {
            required
            actionedAt
          }
          corrective {
            required
            actionedAt
          }
          preventative {
            required
            actionedAt
          }
        }
        openedAt
        createdAt
        closedAt
        duration
        asset {
          id
          name
          zone {
            id
            name
            site {
              id
              name
            }
          }
        }
        events(actionEventsOnly: true) {
          id
          title
          property_name
          description
          time
          incidentRootCauseTypeId
          incidentRootCauseType {
            id
            name
          }
          incidentCorrectiveActionTypeId
          incidentCorrectiveActionType {
            id
            name
          }
          incidentPreventiveActionTypeId
          incidentPreventiveActionType {
            id
            name
          }
          metadata {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITY_LOGS_QUERY = gql`
  query activityLogs(
    $page: Int
    $assetId: ID
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    activityLogs(
      page: $page
      assetId: $assetId
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        description
        userId
        user {
          id
          firstName
          lastName
        }
        time
        siteId
        userType
        site {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        changes {
          property
          from
          to
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_LOGS_QUERY = gql`
  query notificationLogs(
    $page: Int
    $companyId: ID
    $incidentId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    notificationLogs(
      page: $page
      companyId: $companyId
      incidentId: $incidentId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        type
        description
        time
        createdAt
        recipient
        status
        companyId
        company {
          id
          name
        }
        recipientId
        recipientType
        userId
        user {
          id
          email
          firstName
          lastName
        }
        contactId
        contact {
          id
          name
          email
        }
        metadata
      }
    }
  }
`;

export const GET_DEVICE_CERTIFICATES_QUERY = gql`
  query (
    $page: Int
    $companyId: ID!
    $deviceId: ID!
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    deviceCertificates(
      page: $page
      companyId: $companyId
      deviceId: $deviceId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        issueDate
        expireDate
        fileId
        deviceId
        file {
          id
          name
          originalName
        }
        device {
          id
          name
        }
      }
    }
  }
`;

export const GET_DEVICE_CERTIFICATE_READABLE_FILE_LINK_QUERY = gql`
  query deviceCertificate($id: ID!) {
    deviceCertificate(id: $id) {
      id
      readableFile(isDownload: false) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_DEVICE_CERTIFICATE_READABLE_FILE_DOWNLOAD_LINK_QUERY = gql`
  query deviceCertificate($id: ID!) {
    deviceCertificate(id: $id) {
      id
      readableFile(isDownload: true) {
        url
        id
        name
        originalName
        contentType
        category
      }
    }
  }
`;

export const GET_SELECTED_DEVICE_CERTIFICATES_READABLE_FILE_DOWNLOAD_LINK_QUERY = gql`
  query deviceCertificatesReadableFiles($companyId: ID!, $ids: [ID!]!) {
    deviceCertificatesReadableFiles(
      ids: $ids
      companyId: $companyId
      isDownload: true
    ) {
      id
      url
      name
      originalName
      contentType
      category
    }
  }
`;

export const GET_SITE_SYSTEM_CHECKS_QUERY = gql`
  query systemChecks(
    $page: Int
    $companyId: ID!
    $sort: [InputSort]
    $type: String
    $filter: [InputFilter]
  ) {
    systemChecks(
      page: $page
      companyId: $companyId
      type: $type
      category: "SITE"
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        name
        type
        fromDate
        toDate
        status
        siteId
        completedAt
        completedBy
        completedByUser {
          id
          email
          firstName
          lastName
        }
        site {
          id
          name
        }
        incidentStats {
          total
          incomplete
        }
        devicePerformances {
          assetId
          assetName
          deviceId
          deviceName
          propertyName
          propertyGroupName
          propertyGroupId
          optimalBelowLowCount
          optimalCount
          optimalAboveHighCount
          totalCount
          optimalBelowLowPercentage
          optimalPercentage
          optimalAboveHighPercentage
          incidentBelowLowCount
          incidentAboveHighCount
          incidentBelowLowPercentage
          incidentAboveHighPercentage
          outOfServiceAt
        }
      }
    }
  }
`;

export const GET_ZONE_SYSTEM_CHECKS_QUERY = gql`
  query systemChecks(
    $page: Int
    $companyId: ID!
    $sort: [InputSort]
    $type: String
    $filter: [InputFilter]
  ) {
    systemChecks(
      page: $page
      companyId: $companyId
      type: $type
      category: "ZONE"
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        name
        type
        fromDate
        toDate
        status
        siteId
        zoneId
        completedAt
        completedBy
        completedByUser {
          id
          email
          firstName
          lastName
        }
        site {
          id
          name
        }
        zone {
          id
          name
        }
        incidentStats {
          total
          incomplete
        }
        devicePerformances {
          assetId
          assetName
          deviceId
          deviceName
          propertyName
          propertyGroupName
          propertyGroupId
          optimalBelowLowCount
          optimalCount
          optimalAboveHighCount
          totalCount
          optimalBelowLowPercentage
          optimalPercentage
          optimalAboveHighPercentage
          incidentBelowLowCount
          incidentAboveHighCount
          incidentBelowLowPercentage
          incidentAboveHighPercentage
          outOfServiceAt
        }
      }
    }
  }
`;

export const GET_SYSTEM_CHECK_QUERY = gql`
  query systemCheck($id: ID!) {
    systemCheck(id: $id) {
      id
      name
      type
      fromDate
      toDate
      status
      category
      siteId
      zoneId
      zone {
        id
        name
        assets {
          count
        }
      }
      site {
        id
        name
        assets {
          count
        }
      }
      incidentStats {
        total
        incomplete
      }
      completedBy
      completedByUser {
        id
        email
        firstName
        lastName
      }
      completedAt
      companyId
      company {
        id
        uuid
        name
      }
    }
  }
`;

export const GET_SITE_SUMMARY_FOR_SYSTEM_CHECK_QUERY = gql`
  query getSiteDevicePropertySummary($id: ID!, $from: String!, $to: String) {
    site(id: $id) {
      id
      devicePropertySummary(from: $from, to: $to) {
        name
        avg
        min
        max
        last
        unit
        deviceTypeName
        serial
        deviceId
        deviceName
        assetId
        assetName
        assetType
        propertyGroupName
        propertyGroupId
        zoneName
        zoneId
        outOfServiceAt
      }
    }
  }
`;

export const GET_SITE_PERFORMANCE_FOR_SYSTEM_CHECK_QUERY = gql`
  query getSitePerformancesQuery($id: ID!, $from: String!, $to: String!) {
    site(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        zoneName
        zoneId
        assetId
        assetName
        deviceId
        deviceName
        propertyName
        propertyGroupName
        propertyGroupId
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_ZONE_SUMMARY_FOR_SYSTEM_CHECK_QUERY = gql`
  query getSiteDevicePropertySummary($id: ID!, $from: String!, $to: String) {
    zone(id: $id) {
      id
      devicePropertySummary(from: $from, to: $to) {
        name
        avg
        min
        max
        last
        unit
        deviceTypeName
        serial
        deviceId
        deviceName
        assetId
        assetName
        assetType
        propertyGroupName
        propertyGroupId
        outOfServiceAt
      }
    }
  }
`;

export const GET_ZONE_PERFORMANCE_FOR_SYSTEM_CHECK_QUERY = gql`
  query getZonePerformancesQuery($id: ID!, $from: String!, $to: String!) {
    zone(id: $id) {
      id
      devicePerformances(from: $from, to: $to) {
        assetId
        assetName
        deviceId
        deviceName
        propertyName
        propertyGroupName
        propertyGroupId
        optimalBelowLowCount
        optimalCount
        optimalAboveHighCount
        totalCount
        optimalBelowLowPercentage
        optimalPercentage
        optimalAboveHighPercentage
        incidentBelowLowCount
        incidentAboveHighCount
        incidentBelowLowPercentage
        incidentAboveHighPercentage
        outOfServiceAt
      }
    }
  }
`;

export const GET_SYSTEM_CHECK_COMMENTS_QUERY = gql`
  query systemCheckComments(
    $page: Int
    $companyId: ID!
    $systemCheckId: ID!
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    systemCheckComments(
      page: $page
      companyId: $companyId
      systemCheckId: $systemCheckId
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        comment
        time
        userId
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_INCIDENT_STATS_QUERY = gql`
  query incidentStats($companyId: ID!, $siteId: ID, $zoneId: ID, $assetId: ID) {
    incidentStats(
      companyId: $companyId
      siteId: $siteId
      zoneId: $zoneId
      assetId: $assetId
    ) {
      open
      closed
      unAcknowledged
      incomplete
      completed
      all
    }
  }
`;

export const GET_ASSET_FILES_QUERY = gql`
  query assetFiles($id: ID!) {
    asset(id: $id) {
      id
      files {
        id
        assetId
        fileId
        file {
          id
          name
          originalName
          createdAt
        }
      }
    }
  }
`;

export const GET_ASSET_INHERITED_DEVICE_CERTIFICATES_FILES_QUERY = gql`
  query assetInheritedDeviceCertificates($id: ID!) {
    asset(id: $id) {
      id
      inheritedDeviceCertificates {
        id
        name
        issueDate
        expireDate
        fileId
        deviceId
        file {
          id
          name
          originalName
          createdAt
        }
        device {
          id
          name
        }
      }
    }
  }
`;

export const GET_ASSET_ACTIVITY_LOGS_QUERY = gql`
  query assetActivityLogs(
    $page: Int
    $assetId: ID
    $companyId: ID
    $from: String
    $to: String
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    activityLogs(
      page: $page
      assetId: $assetId
      companyId: $companyId
      from: $from
      to: $to
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        description
        time
        userType
        changes {
          property
          from
          to
        }
      }
    }
  }
`;

export const LOOKUP_REVERSE_GEO_CODE_MUTATION = gql`
  query lookupReverseGeoCode($lat: Float!, $lng: Float!) {
    lookupReverseGeoCode(lat: $lat, lng: $lng) {
      address
    }
  }
`;

export const GET_SITES_LOCATIONS_QUERY = gql`
  query siteLocations(
    $page: Int
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    sites(
      pageSize: 1000
      page: $page
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        lat
        lng
        assets {
          count
        }
        currentWeather {
          name
          main
          description
          icon
          tempNow
          tempMin
          tempMax
          pressure
          humidity
          windSpeed
          windDeg
          timestamp
          distance
        }
        stats {
          id
          incidents {
            open
          }
        }
      }
    }
  }
`;

export const GET_ASSET_KUBE_VISION_FOOTAGES_QUERY = gql`
  query kubeVisionFootageRequests(
    $page: Int
    $assetId: ID!
    $companyId: ID!
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    kubeVisionFootageRequests(
      page: $page
      assetId: $assetId
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        requestUuid
        deviceId
        assetId
        startTime
        endTime
        type
        status
        reviewStatus
        metadata
        requestedBy
        creationTime
        isDeleteProtected
        driverCommentStatus
        driverCommentRequestedBy
        driverCommentRequestedTime
        requestedByUser {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_ASSET_KUBE_VISION_FOOTAGE_QUERY = gql`
  query kubeVisionFootageRequest($id: ID!) {
    kubeVisionFootageRequest(id: $id) {
      id
      requestUuid
      deviceId
      assetId
      startTime
      endTime
      type
      status
      reviewStatus
      metadata
      requestedBy
      creationTime
      reviewRequestedBy
      reviewRequestedTime
      reviewCompletedBy
      reviewCompletionTime
      driverId
      isDeleteProtected
      driverCommentStatus
      driverCommentRequestedBy
      driverCommentRequestedTime
      requestedByUser {
        id
        email
        firstName
        lastName
      }
      reviewRequestedByUser {
        id
        email
        firstName
        lastName
      }
      reviewCompletedByUser {
        id
        email
        firstName
        lastName
      }
      driverCommentRequestedByUser {
        id
        email
        firstName
        lastName
      }
      driver {
        id
        email
        firstName
        lastName
      }
      asset {
        id
        name
      }
    }
  }
`;

export const GET_KV_FOOTAGE_REQUEST_ACTIVITY_LOGS_QUERY = gql`
  query activityLogs(
    $page: Int
    $companyId: ID
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    activityLogs(
      page: $page
      companyId: $companyId
      sort: $sort
      filter: $filter
    ) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        description
        userId
        userType
        user {
          id
          firstName
          lastName
        }
        time
        siteId
        site {
          id
          name
        }
        companyId
        company {
          id
          name
        }
        changes {
          property
          from
          to
        }
      }
    }
  }
`;

export const GET_FOOTAGE_REQUEST_COMMENTS_QUERY = gql`
  query kubeVisionFootageRequestComments(
    $page: Int
    $footageRequestId: ID!
    $sort: [InputSort]
    $filter: [InputFilter]
  ) {
    kubeVisionFootageRequestComments(
      page: $page
      footageRequestId: $footageRequestId
      sort: $sort
      filter: $filter
    ) {
      count
      page
      pages
      nodes {
        id
        comment
        time
        userId
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
