import React from 'react'
import {
  Row,
  Col,
  Button,
  Alert,
  ModalHeader,
  ModalBody, Modal, ModalFooter, Label, Container,
} from 'reactstrap'
import { Formik } from 'formik'
import DynamicFormikField from '../../../components/forms/DynamicFormikField'
import TinySpinner from '../../../components/TinySpinner'
import { FieldConfigs } from './UpdateProfileFields'
import useUpdateProfileForm from './useUpdateProfileForm'
import FileUploadFormItem from '../../../components/forms/FileUploadFormItem'
import CardLoader from '../../../components/loaders/CardLoader'

const UpdateProfile = React.memo(function UpdateProfile({
                                                          isOpen,
                                                          setIsOpen,
                                                          ...props
                                                        }) {

  const {
    error,
    formikOptions,
    success,
    successMessage,
    errorMessage,
    initialised,
  } = useUpdateProfileForm({
    ...props,
    setIsOpen,
  })
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formikOptions

  return (
    <Modal isOpen={isOpen}>
      <Formik {...formikOptions}>
        <form onSubmit={handleSubmit}>
          <ModalHeader className='border-bottom-0'>Update
            Profile</ModalHeader>
          <ModalBody>
            <div className='container-content'>
              <Row className=''>
                <Col>
                  {error ? (
                    <Alert color='danger' isOpen={!!error}>
                      <div>{errorMessage}</div>
                    </Alert>
                  ) : (
                    success && (
                      <Alert color='success'>
                        <div>{successMessage}</div>
                      </Alert>
                    )
                  )}
                  {!initialised ? (
                    <CardLoader />
                  ) : (
                    <React.Fragment>
                      <Row className='margin-bottom-10' form>
                        <Col>
                          <Label>Profile Picture</Label>
                          <FileUploadFormItem name='profilePictureFile'
                                              setFieldValue={setFieldValue}
                                              value={values.profilePictureFile}
                                              accept={{'image/*': [".png",".jpg",".jpeg"]}} />
                        </Col>
                      </Row>
                      <Row>
                        {FieldConfigs.map((fieldConfig, configIndex) => (
                          <Col md={fieldConfig.colSpan || 12}
                               key={`form-field-key-${configIndex}`}>
                            <DynamicFormikField
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              values={values}
                              touched={touched}
                              errors={errors}
                              fieldConfig={fieldConfig} />
                          </Col>
                        ))}
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className='border-top-0'>
            <Container>
              <Row>
                {initialised && (
                  <React.Fragment>
                    <Col>
                      <Button color='primary' className='w-100'
                              type='submit' disabled={isSubmitting}>
                        {!isSubmitting && <span>Submit</span>}
                        {isSubmitting && (
                          <span>Submitting<TinySpinner /></span>
                        )}
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button className='w-100' color='secondary'
                              onClick={() => {
                                setIsOpen(false)
                              }}>Close</Button>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Container>
          </ModalFooter>
        </form>
      </Formik>
    </Modal>
  )
})


export default UpdateProfile
