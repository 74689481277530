import * as Yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { passwordRegex } from '../../../constants/validationRegex'

const FieldConfigs = [{
  name: 'email',
  label: 'Email',
  placeholder: 'Enter email',
}, {
  name: 'firstName',
  label: 'First Name',
  placeholder: 'Enter first name',
  autoFocus: true,
  colSpan: 6,
}, {
  name: 'lastName',
  label: 'Last Name',
  placeholder: 'Enter last name',
  colSpan: 6,
}, {
  name: 'newPassword',
  label: 'Password',
  placeholder: 'Enter password',
  colSpan: 6,
  type: 'password',
}, {
  name: 'rePassword',
  label: 'Re Password',
  placeholder: 'Enter password',
  colSpan: 6,
  type: 'password',
}, {
  name: 'telephone',
  label: 'Telephone',
  placeholder: 'Enter telephone number',
  colSpan: 6,
}]


const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required').max(70, 'Must be at lower than 70 characters'),
  firstName: Yup.string().required('First name is required').max(50, 'Must be at lower than 50 characters'),
  lastName: Yup.string().required('Last name is required').max(50, 'Must be at lower than 50 characters'),
  telephone: Yup.string().test({
    name: 'telephone',
    message: 'Phone number is not valid',
    test: (val) => {
      if (val) {
        return isValidPhoneNumber(val, 'GB')
      }
      return true
    },
  }),
  password: Yup.string().required('Password is required'),
  newPassword: Yup.string().required('Enter your new password').min(7, 'Must be at least 7 characters').test({
    name: 'newPassword',
    message: 'Password should contain at least 1 upper case letter, 1 number or special character',
    test: (val) => val && passwordRegex.test(val),
  }),
  rePassword: Yup.string().required('Enter your password again').oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})


export { FieldConfigs, ValidationSchema }
